@import "../../styles/variables";

.accordion {
    composes: gridContainer from "../../styles/layout.module.scss";
    --grid-gap: 40px;

    @media ($below-mobile) {
        --grid-gap: 20px;
    }
}

.full {
    composes: fullWidthContainer from "../../styles/layout.module.scss";
}

.padded {
    composes: paddedContainer from "../../styles/layout.module.scss";
}

.list {
    composes: listReset from "../../styles/utility.module.scss";
    border-top: 1px solid $pewter1;
}

.listItem {
    border-bottom: 1px solid $pewter1;
}

.button {
    composes: buttonReset from "../Clickable/button.module.scss";
    width: 100%;
    padding: 20px 35px 20px 0;
    line-height: 1.5em;
    text-align: left;

    p {
        margin: 0;
    }
}

.heading {
    position: relative;
    margin: 0;
    font-size: 16px;

    @media ($below-mobile) {
        font-size: 14px;
    }
}

.plusIcon,
.minusIcon {
    position: absolute;
    right: 0;
    top: 50%;
    opacity: 0;
    transform: translateY(-50%);
    transition: opacity 0.2s;
}

.plusIcon {
    width: 20px;
    height: 20px;

    @media ($below-mobile) {
        width: 18px;
        height: 18px;
    }

    .button[aria-expanded="false"] & {
        opacity: 1;
    }
}

.minusIcon {
    width: 20px;
    height: 2px;
    margin-top: -1px;

    @media ($below-mobile) {
        width: 18px;
        height: 18px;
    }

    .button[aria-expanded="true"] & {
        opacity: 1;
    }
}

.panel {
    margin-bottom: 20px;
}
