@import "../../styles/variables";

.testimonialCards {
    background-color: $pewter1;
    border-top: 10px solid $satin-brass;
    padding-top: 60px;
    padding-bottom: 60px;

    @media ($below-mobile) {
        padding-top: 30px;
        padding-bottom: 30px;
    }
}

.cardsHeader {
    composes: gridContainer from "../../styles/layout.module.scss";
    margin-bottom: 40px;

    @media ($below-mobile) {
        margin-bottom: 25px;
    }
}

.testimonial {
    @media ($above-mobile) {
        composes: gridContainer from "../../styles/layout.module.scss";
        align-items: center;
    }

    @media ($below-mobile) {
        display: flex;
        flex-direction: column;
    }

    .blockContentContainer {
        grid-column: 2 / span 5;

        @media ($below-tablet) {
            grid-column: 1 / span 7;
        }

        @media ($below-mobile) {
            order: 2;
        }

        figure {
            @media ($below-tablet) {
                padding-right: 20px;
            }
        }
    }

    .blockImageContainer {
        grid-column: 8 / span 4;
        display: flex;
        align-items: center;
        flex-shrink: 0;

        @media ($below-tablet) {
            grid-column: 8 / span 5;
        }

        @media ($below-mobile) {
            width: 100%;
        }

        img {
            width: 100%;
            height: auto;
        }
    }
}

.full {
    @media ($above-mobile) {
        composes: fullWidthContainer from "../../styles/layout.module.scss";
    }
}

.padded {
    composes: paddedContainer from "../../styles/layout.module.scss";
}

.quoteFigure {
    margin: 0;

    .testimonialCards & {
        padding-bottom: 30px;

        @media ($below-mobile) {
            padding-bottom: 25px;
        }
    }

    blockquote {
        margin: 0;

        p {
            margin-bottom: 0;
        }

        .testimonial & {
            font-size: 24px;
            line-height: 1.6667em;

            @media ($below-tablet) {
                font-size: 21px;
            }

            @media ($below-mobile) {
                font-size: 18px;
            }
        }
    }

    figcaption {
        display: flex;
        gap: 20px;
        align-items: center;

        img {
            width: 120px;
            height: 120px;

            @media ($below-mobile) {
                width: 104px;
                height: 104px;
            }

            @media ($below-tablet) {
                width: 88px;
                height: 88px;
            }
        }

        .testimonial & {
            padding: 30px 0 0;

            @media ($below-mobile) {
                padding-top: 25px;
            }
        }

        .testimonialCards & {
            @media ($above-mobile) {
                padding: 20px 0 10px;
            }
        }

        .figcaptionText {
            display: flex;
            flex-direction: column;
        }
    }
}

.author {
    color: $pewter3;
    font-size: 12px;
    font-weight: 700;
    letter-spacing: 0.083em;
    line-height: 1.333em;
    text-transform: uppercase;

    @media ($below-mobile) {
        margin-top: 10px;
        margin-bottom: 5px;
    }
}

.desktopList {
    composes: container from "../../styles/layout.module.scss";
    composes: listReset from "../../styles/utility.module.scss";
    display: flex;
    gap: 20px;

    li {
        flex: 1 0;
        padding: 30px 30px 0;
    }

    @media ($below-mobile) {
        display: none;
    }
}

.mobileList {
    li {
        width: calc(100% - 15px);
        margin-right: 15px;
        padding: 15px;
    }

    @media ($above-mobile) {
        display: none;
    }
}

.carousel {
    composes: container from "../../styles/layout.module.scss";
    margin-right: 0;
}

.slider {
    width: 100%;
}

.sliderTray {
    composes: listReset from "../../styles/utility.module.scss";
}

.desktopList,
.mobileList {
    li {
        background-color: $white;

        &:not([aria-selected="false"]) {
            box-shadow: 0 1px 5px rgba($black, 0.1);
        }
    }
}

.dotGroup {
    display: flex;
    gap: 10px;
    justify-content: center;
    margin-top: 10px;

    button {
        width: 24px;
        height: 24px;
        background-color: $smoke;
        opacity: 0.3;
        border-top: 11px solid $pewter1;
        border-bottom: 11px solid $pewter1;
        border-left: 0;
        border-right: 0;

        &[disabled] {
            opacity: 1;
        }
    }
}

// Because the slide width is 1.333, the third slide never fully slides into view in the eyes of
// the carousel package. This is a cheap way to fix that problem.
// The designs assume three testimonial cards on the home page; that's why three are targeted here.
// If the first two aren't disabled, then the third slide is the active one.
.threeDotHack {
    button:not([disabled]) ~ button:not([disabled]) ~ button {
        opacity: 1;
    }
}

.richText {
    text-align: center;
}

.quoteIcon {
    width: 32px;
    height: 25px;
    color: $satin-brass;

    @media ($below-mobile) {
        width: 24px;
        height: 19px;
        margin-bottom: 5px;
    }

    .testimonial & {
        @media ($above-mobile) {
            margin-top: 0.5em;
        }
        @media ($above-tablet) {
            margin-top: 1em;
        }
    }

    .testimonialCards & {
        margin-bottom: 10px;
    }
}

.cta {
    color: inherit;
    font-size: 12px;
    font-weight: 300;
    line-height: 1.333em;

    &[href] {
        text-decoration: underline;
    }

    @media ($above-mobile) {
        margin-top: 10px;
    }

    @media ($below-mobile) {
        margin-bottom: 10px;
    }
}
