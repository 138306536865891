@import "../../styles/variables";
@import "../../styles/typography-variables";

.form {
    composes: grid from "../../styles/layout.module.scss";

    p {
        margin-bottom: 0.5em;
        font-size: 14px;

        + p {
            margin-bottom: 0;
        }
    }
}

.full {
    composes: fullWidthContainer from "../../styles/layout.module.scss";
}

.secondaryTitle {
    @include secondary-all-caps;
    font-size: 18px;
}

.fourColumns {
    grid-column: span 4;
}

.sixColumns {
    grid-column: span 6;
}

.twelveColumns {
    grid-column: span 12;
}

@media ($below-mobile) {
    .fourColumns,
    .sixColumns,
    .twelveColumns {
        grid-column: span 4;
    }
}

.checkboxGroup {
    composes: fieldsetReset from "../../styles/utility.module.scss";
}

.invalidList {
    border: 1px solid $red;
}

.textarea textarea {
    min-height: 144px;
}

.returnItem {
    composes: gridContainer from "../../styles/layout.module.scss";
    position: relative;
    background-color: $pewter1;
    margin: 0;
    padding: 20px;

    &:not(:last-child) {
        margin-bottom: 20px;
    }

    & > p {
        font-weight: 700;
        margin-bottom: 0;
    }
}

.addAnotherButton {
    @include button-reset;
    align-items: center;
    color: $pewter3;
    display: inline-flex;
    text-align: left;

    & svg {
        fill: $pewter3;
        max-height: 14px;
        margin-left: 8px;
    }
}

.radioButtonGroup {
    composes: fieldsetReset from "../../styles/utility.module.scss";

    > *:not(:last-child) {
        margin-bottom: 10px;
    }
}

.upsText {
    color: $pewter3;

    & a {
        color: $pewter3;
    }
}

.returnHalfTopMargin {
    margin-top: 30px;
}

.returnTopMargin {
    margin-top: 60px;
}

.radioButtonLabel {
    font-size: 14px;
}

.close {
    position: absolute;
    top: 22px;
    right: 20px;
    width: 20px;
    height: 20px;
}
