@import "../../styles/variables";

.spacer {
    composes: maxSiteWidth from "../../styles/layout.module.scss";
    display: flex;
    flex-direction: row;
    background: transparent;

    $mapHeightsDesktop: (
        "no-height": 0rem,
        "small": 30px,
        "medium": 40px,
        "large": 60px,
        "xlarge": 80px,
        "xxlarge": 100px,
    );

    @each $name, $height in $mapHeightsDesktop {
        &.#{$name} {
            height: $height;
        }
    }

    @media ($below-mobile) {
        $mapHeightsMobile: (
            "no-height": 0rem,
            "small": 20px,
            "medium": 20px,
            "large": 30px,
            "xlarge": 40px,
            "xxlarge": 50px,
        );

        @each $name, $height in $mapHeightsMobile {
            &.#{$name} {
                height: $height;
            }
        }
    }

    svg {
        width: 100%;
        color: $pewter1;
    }
}
