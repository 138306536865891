@import "../../styles/variables";

.container {
    composes: gridContainer from "../../styles/layout.module.scss";
}

.full {
    composes: fullWidthContainer from "../../styles/layout.module.scss";
}

.landscapeImageTiles {
    composes: listReset from "../../styles/utility.module.scss";

    display: grid;
    grid-column: 1 / -1;
    grid-gap: 30px 20px;
    grid-template-columns: repeat(3, 1fr);

    @media ($below-tablet) {
        grid-template-columns: repeat(2, 1fr);
        grid-gap: 15px;
    }
}

.landscapeImage {
    height: calc(100% - 1.6rem);
    grid-column: 1 / 3;

    @media ($below-tablet) {
        grid-column: 1 / -1;
    }

    & section {
        height: 100%;

        & > div {
            height: 100%;
        }
    }

    // funky selector to target HeroSet element
    & [data-hero-placement="background-full"] > *:nth-of-type(1) {
        --container-margin: 60px;
    }
}

.tileClickable {
    composes: secondaryAllCaps from "../../styles/typography.module.scss";

    color: $black;
    text-decoration: none;
}
