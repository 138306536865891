@import "../../styles/variables";

.tile {
    position: relative;
    width: 100%;
}

.tileListItem {
    &:hover,
    &:focus-within {
        outline: 1px solid $satin-brass;
    }
}

.clickable {
    display: block;
    position: relative;
    color: inherit;
    outline: 1px solid transparent;
    outline-offset: calc(var(--grid-gap) * 0.45);
    text-decoration: none;
    transition: outline 0.2s;

    &:last-child {
        margin-bottom: 0;
    }

    p {
        margin: 0.75em 0;
        color: $pewter3;
        font-size: 12px;
        line-height: 1.33em;

        + p {
            font-size: 11px;
        }
    }
}

.imageContainer {
    position: relative;
    width: 100%;
    height: 0;
    padding-bottom: 100%;
    border-bottom: 1px solid $pewter1;

    > img {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }
}

.tileTextContainer {
    padding: 0 10px 15px;
}

.tag {
    position: absolute;
    top: 0;
    left: 0;
    padding: 0px 8px;
    background-color: $navy;
    color: $white;
    font-family: $secondary-font-family;
    font-size: 10px;
    letter-spacing: 0.1em;
    pointer-events: none;
    text-transform: uppercase;
    z-index: 1;
}

.icon {
    height: 30px;
}

.iconGroup {
    display: flex;
    flex-wrap: wrap;
    gap: 5px;
    padding-top: 10px;
}

.fakeGridTile {
    pointer-events: none;
    user-select: none;

    > * {
        color: $pewter1 !important;
        background-color: $pewter1;
    }

    h1 {
        display: inline-block;
    }
}
