@import "../../styles/variables";

.radio {
    $satin-brass-border: 1px solid $satin-brass;
    cursor: pointer;
    display: grid;
    font-size: 0.875rem;
    gap: 0.5rem;
    grid-template-columns: 1rem auto;
    line-height: 1.25rem;

    input {
        appearance: none;
        background-color: $white;
        border-radius: 50%;
        border: 1px solid $pewter2;
        cursor: pointer;
        display: grid;
        height: 1rem;
        margin: 0;
        place-content: center;
        transform: translateY(2px);
        width: 1rem;

        &::before {
            background-color: $satin-brass;
            border-radius: 50%;
            content: "";
            height: 0.625rem;
            outline: $satin-brass-border;
            outline-offset: 2px;
            transform: scale(0);
            transition: 120ms transform ease-in-out;
            width: 0.625rem;
        }

        &:checked {
            &::before {
                transform: scale(1);
            }
        }

        &:focus {
            &::before {
                outline: $satin-brass-border;
                outline-offset: 2px;
            }
        }

        &:hover {
            border: $satin-brass-border;
        }
    }
}
