@import "../../styles/variables";

.gridList {
    composes: grid from "../../styles/layout.module.scss";
    composes: listReset from "../../styles/utility.module.scss";
    margin-bottom: 20px;

    li {
        @media ($below-mobile) {
            grid-column: span 2;
        }

        @media ($above-mobile) and ($below-tablet) {
            grid-column: span 6;
        }
    }

    &.perRow3 li {
        @media ($above-tablet) {
            grid-column: span 4;
        }
    }

    &.perRow4 li {
        @media ($above-tablet) {
            grid-column: span 3;
        }
    }
}
