@import "../../styles/variables";

.root {
    align-items: center;
    cursor: pointer;
    display: flex;
    font-size: 14px;
    position: relative;

    input {
        display: none;

        &:hover {
            & + span {
                border-color: $satin-brass;
            }
        }

        &[aria-checked="true"] {
            & + span {
                border-color: $satin-brass;

                & svg {
                    display: block;
                }
            }
        }
    }
}

.checkbox {
    align-items: center;
    border: 1px solid $pewter2;
    display: flex;
    flex-shrink: 0;
    height: 16px;
    justify-content: center;
    margin-right: 15px;
    width: 16px;
}

.checkmark {
    display: none;
    height: 6px;
    width: 8px;
}
