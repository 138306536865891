@import "../../styles/variables";

.fullWidthCarousel {
    overflow-x: hidden;
}

.overflowingWithCarousels {
    composes: gridContainer from "../../styles/layout.module.scss";
    composes: paddedContainer from "../../styles/layout.module.scss";
    --img-aspect-ratio: calc(5 / 8);
    --img-height: calc(var(--padded-container-width) * var(--img-aspect-ratio));

    :global(.carousel) {
        display: flex;
        flex-direction: column;
        position: relative;
        width: 100%;

        & > div {
            @media ($above-mobile) {
                overflow: unset;
            }
        }
    }
}

.full {
    composes: fullWidthContainer from "../../styles/layout.module.scss";
}

.dotGroup {
    composes: dotGroup from "../GenericCarousel/generic-carousel.module.scss";

    order: 1;
}

.dot {
    composes: buttonReset from "../Clickable/button.module.scss";
    max-width: 78px;

    @media ($below-mobile) {
        & {
            max-width: 50px;
        }
    }

    &[disabled] {
        border: 2px solid $satin-brass;
    }
}

.slide {
    margin: 0 10px;

    @media ($below-mobile) {
        & {
            margin: 0;
        }
    }

    &[aria-selected="false"] {
        & figcaption {
            opacity: 0;
        }
    }

    figure {
        margin: 0;

        & img {
            @media ($below-mobile) {
                width: 100%;
            }
        }
    }

    figcaption {
        color: $pewter3;
        font-size: 12px;
        line-height: 16px;
        margin: 15px 0 20px 0;
        opacity: 1;
        text-align: center;
        transition: opacity 0.2s;

        a {
            font-weight: inherit;

            &:not(:only-of-type) {
                margin-right: 0;
            }
        }
    }
}

.previous,
.next {
    composes: buttonReset from "../Clickable/button.module.scss";
    position: absolute;
    top: calc(((var(--img-height) / 2) - var(--button-dimension)) + 10px);
    z-index: 1;

    @media ($below-tablet) {
        display: none;
    }
}

.previous {
    composes: previous from "../GenericCarousel/generic-carousel.module.scss";
    left: 10px;

    @media ($below-mobile) {
        left: 10px;
    }
}

.next {
    composes: next from "../GenericCarousel/generic-carousel.module.scss";
    right: 10px;

    @media ($below-mobile) {
        right: 10px;
    }
}
