@import "../../styles/variables";

.imageTiles {
    composes: container from "../../styles/layout.module.scss";
    display: flex;
    gap: 20px;

    @media ($above-mobile) {
        > * {
            width: 50%;
        }
    }

    @media ($below-mobile) {
        flex-direction: column;
    }

    header {
        position: relative;
    }

    .headerContent {
        padding-bottom: 10px;

        @media ($above-mobile) {
            position: absolute;
            top: 0;
            left: 0;
            padding-right: 20px;
            background-color: $white;
            z-index: 1;
        }

        @media ($below-mobile) {
            width: 100%;
            text-align: center;
        }
    }

    .headingImage {
        @media ($above-mobile) {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            object-fit: cover;
            z-index: 0;
        }
    }

    .heading {
        margin-bottom: 0.66em;

        @media ($below-mobile) {
            // If not !important, then we need the component to watch
            // window.innerWidth and not do its thing on mobile
            width: 100% !important;
            margin-bottom: 0.2em;
        }
    }

    ul {
        display: flex;
        flex-wrap: wrap;
        gap: 20px;
    }

    li {
        width: calc(50% - 10px);
        flex-shrink: 0;
    }

    a {
        display: flex;
        flex-direction: column;
        gap: 15px;
        color: inherit;
    }
}

.full {
    composes: fullWidthContainer from "../../styles/layout.module.scss";
}

.tileList {
    composes: listReset from "../../styles/utility.module.scss";

    a {
        text-decoration: none;
    }

    img {
        aspect-ratio: 1;
    }
}

.tileClickable {
    font-family: var(
        --image-tiles--tile-clickable-font,
        #{$secondary-font-family}
    );
    font-weight: 400;
    letter-spacing: 0.07em;
    line-height: 1.4em;
    text-transform: uppercase;
}
