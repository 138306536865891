@import "../../styles/variables";

.wrapper {
    background: linear-gradient(
        to bottom,
        $white 20%,
        var(--background-color) 20%
    );
    --background-color: #{$smoke};
    --dot-color: #{$white};
}

.carouselWithBackground {
    composes: gridContainer from "../../styles/layout.module.scss";
    composes: paddedContainer from "../../styles/layout.module.scss";
    --img-aspect-ratio: calc(5 / 8);
    --img-height: calc(var(--padded-container-width) * var(--img-aspect-ratio));

    :global(.carousel) {
        position: relative;
        display: flex;
        flex-direction: column;
    }
}

.full {
    composes: fullWidthContainer from "../../styles/layout.module.scss";
}

.slide {
    composes: slide from "../GenericCarousel/generic-carousel.module.scss";
    color: $white;

    figure {
        margin: 0px;
    }

    img {
        width: 100%;
    }

    figcaption {
        margin-top: 20px;
        font-size: 14px;

        @media ($above-mobile) {
            text-align: center;
        }
    }
}

.dotGroup {
    margin-top: 20px;
    margin-bottom: 30px;
    order: 1;

    @media ($below-mobile) {
        margin-top: 10px;
        margin-bottom: 20px;
    }

    button:only-child {
        visibility: hidden;
    }
}

.next,
.back {
    position: absolute;

    // Centers button vertically
    top: calc((var(--img-height) - var(--button-dimension)) / 2);
    z-index: 1;

    .onlyOneSlide & {
        display: none;
    }
}

.next {
    right: 0px;
}

.back {
    left: 0px;
}
