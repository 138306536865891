@import "../../styles/variables";

.crossSell {
    composes: container from "../../styles/layout.module.scss";
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 40px;

    @media ($below-mobile) {
        gap: 20px;
    }
}

.headerPadded {
    composes: grid from "../../styles/layout.module.scss";
    composes: paddedContainer from "../../styles/layout.module.scss";
}

.full {
    composes: fullWidthContainer from "../../styles/layout.module.scss";
}

.container {
    display: flex;
    width: 100%;
    justify-content: center;
    // use variable to make outline hover work properly
    --grid-gap: 20px;
    gap: var(--grid-gap);

    @media ($below-tablet) {
        --grid-gap: 15px;
    }

    @media ($below-mobile) {
        flex-wrap: wrap;
    }

    > article {
        width: 25%;
        @media ($below-mobile) {
            width: calc(50% - 7.5px);
            margin-bottom: 1em;
        }
    }
}

.cta {
    display: inline-block;
}
