@import "../../styles/variables";

$column-gap: 20px;
$half-of-space-outside-of-container: calc((100vw - #{$container-width}) / 2);

// Used in conjunction with the container layout class, but we're overriding
// the margin-right and adding that width into the width of the container
.imageTiles {
    composes: container from "../../styles/layout.module.scss";
    // Floats made a bit more sense in my mental model for this than flex
    display: flow-root;
    max-width: calc(
        #{$container-width} + calc(calc(100% - #{$container-width}) / 2)
    );
    margin-right: 0;
    // vw is used throughout, which lets you get 100% of the window width in
    // nested elements, but it doesn't account for the scrollbar width. So we have
    // to account for that; this seemed ideal
    overflow: hidden;
    background-color: var(--background-color, $white);

    > * {
        float: left;
    }

    header {
        position: relative;
        // width of element, minus the extra margin-right (60px or half of space
        // outside of the container, whichever is bigger), minus 3x the column gap
        // (because there are 60px of gutter space we want to distribute in total),
        // divided by 4. This gives us the correct width of this element, with room
        // to add the margin-right.
        width: calc(
            (
                    100% - #{unquote(
                            "max(#{$half-of-space-outside-of-container}, #{$container-margin-desktop})"
                        )} - #{$column-gap * 3}
                ) / 4
        );
        padding-right: 24px;
        margin-right: 20px;

        @media ($below-tablet) {
            width: calc(
                (100% - #{$container-margin-tablet} - #{$column-gap * 2}) / 3
            );
        }

        @media ($below-mobile) {
            width: 100%;
            padding-right: $container-margin-mobile;
            text-align: center;
        }
    }

    // width of element, minus the extra margin-right (60px or half of space
    // outside of the container, whichever is bigger), plus the column gap,
    // divided by 4, times 3. This gives us the correct width of this element
    .carousel {
        position: relative;
        width: calc(
            (
                    100% - #{unquote(
                            "max(#{$half-of-space-outside-of-container}, #{$container-margin-desktop})"
                        )} + #{$column-gap}
                ) / 4 * 3
        );

        @media ($below-tablet) {
            width: calc(
                (100% - #{$container-margin-tablet - $column-gap}) / 3 * 2
            );
        }

        @media ($below-mobile) {
            width: 75%;
            margin-top: $column-gap;
        }
    }

    .dotGroup {
        margin-top: 30px;

        @media ($below-mobile) {
            width: calc(133% - #{$container-margin-mobile});
            margin-top: 10px;
        }
    }

    a {
        @include imageTilesLink;
    }
}

.full {
    margin-left: 0;
}

.slide {
    composes: slide from "../GenericCarousel/generic-carousel.module.scss";

    padding-right: $column-gap;
}

.clickable {
    font-family: var(
        --tile-carousel--clickable-font,
        #{$secondary-font-family}
    );
    font-weight: 400;
    letter-spacing: 0.07em;
    line-height: 1.4em;
    text-transform: uppercase;

    img {
        aspect-ratio: 1;
    }
}

.button {
    top: 38%;

    @media ($below-tablet) {
        width: 30px;
        padding: 10px 3px;
    }
}

.buttonPrevious {
    composes: button;
    left: 0;
    transform: translate(-100%, -50%);
}

.buttonNext {
    composes: button;
    right: 0;
    transform: translate(55%, -50%);

    @media ($below-tablet) {
        transform: translate(33%, -50%);
    }
}

// This extends the slider out past the container boundary so that formerly
// hidden slides can be visible without messing with the expected grid layout
.slider {
    composes: listReset from "../../styles/utility.module.scss";
    width: calc(
        100% +
            unquote(
                "max(#{$half-of-space-outside-of-container}, #{$container-margin-desktop})"
            )
    );

    @media ($below-tablet) {
        width: calc(
            100% +
                unquote(
                    "max(#{$half-of-space-outside-of-container}, #{$container-margin-tablet})"
                )
        );
    }

    @media ($below-mobile) {
        width: 133%;
    }

    // This is what's extending the slide viewport to the right so you can see
    // slides that would otherwise be hidden (the ones that are translucent)
    > div {
        position: relative;
        width: calc(
            100% - unquote(
                    "max(#{$half-of-space-outside-of-container}, #{$container-margin-desktop})"
                )
        );

        @media ($below-tablet) {
            width: calc(
                100% - unquote(
                        "max(#{$half-of-space-outside-of-container}, #{$container-margin-tablet})"
                    )
            );
        }

        @media ($below-mobile) {
            width: 75%;
        }
    }
}
