@import "../../styles/variables";
@import "../../styles/typography-variables";

.root :global {
    .contactus-maindiv {
        background-color: transparent !important;
    }

    .contactus-container {
        margin-left: 0;
    }

    .col-left {
        display: none;
    }

    .col-right {
        max-width: 100%;
    }

    .column-right {
        margin-bottom: 0.5em;
    }

    button[type="submit"] {
        @include button-primary;
    }
}
