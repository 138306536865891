@import "../../styles/variables";

.dotGroup {
    display: flex;
    gap: 10px;
    justify-content: center;
}

.dot {
    composes: buttonReset from "../Clickable/button.module.scss";
    width: 24px;
    height: 24px;
    background-color: var(--dot-color, $smoke);
    opacity: 0.3;
    border-top: 11px solid var(--background-color, $white);
    border-bottom: 11px solid var(--background-color, $white);

    &[disabled] {
        opacity: 1;
    }
}

.slide {
    opacity: 1;
    transition: opacity 0.2s;

    &[aria-selected="true"] ~ *[aria-selected="false"] {
        opacity: 0.3;
    }
}

.button {
    composes: buttonReset from "../Clickable/button.module.scss";
    position: absolute;
    --button-dimension: 44px;
    width: var(--button-dimension);
    height: var(--button-dimension);
    padding: 10px;
    background-color: rgba($white, 0.7);
    transition: background-color 0.2s;

    @media ($below-mobile) {
        display: none;
    }

    &:hover:not([disabled]) {
        background-color: $pewter3;
        svg {
            color: $white;
            transition: color 0.2s;
        }
    }

    &[disabled] {
        svg {
            color: $pewter3;
            opacity: 0.5;
        }
    }
}

.previous {
    composes: button;

    svg {
        transform: scale(-1, 1);
    }
}

.next {
    composes: button;
}
