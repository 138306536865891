@import "../../styles/variables";

.tooltip {
    background: $pewter3;
    color: $white;
    border: none;
    border-radius: 2px;
    padding: 8px 10px;
    max-width: 250px;
    white-space: normal;
    font-size: 12;
    line-height: 1.3333;
    font-weight: 400;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
    z-index: 100000;
}
