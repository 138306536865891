@import "../../styles/variables";

.root {
    composes: container from "../../styles/layout.module.scss";
    display: flex;
    flex-direction: column;
}

.header {
    margin-bottom: 40px;
    width: 100%;
    text-align: center;

    @media ($below-mobile) {
        margin-bottom: 20px;
    }
}

.headerPadded {
    composes: grid from "../../styles/layout.module.scss";
    composes: paddedContainer from "../../styles/layout.module.scss";
}

.full {
    composes: fullWidthContainer from "../../styles/layout.module.scss";
}

.ctaImage {
    composes: slide from "../GenericCarousel/generic-carousel.module.scss";
    composes: secondaryAllCaps from "../../styles/typography.module.scss";
    composes: linkReset from "../Clickable/button.module.scss";
    @include imageTilesLink;
}

.clickableTile {
    display: flex;
    flex-direction: column;
    border: 2px solid transparent;
    color: inherit;
    padding: 40px;
    text-decoration: none;

    .richText {
        flex: 1;
    }

    &:hover {
        border: 2px solid $satin-brass;
    }

    @media ($above-mobile) and ($below-tablet) {
        padding: 30px;
    }

    @media ($above-mobile) {
        min-height: 300px;
    }

    @media ($below-mobile) {
        padding: 20px;
    }
}

.clickableTileLink {
    composes: secondaryAllCaps from "../../styles/typography.module.scss";
    display: inline-block;
    margin-top: 16px;
    text-decoration: underline;
}

.imageContent {
    margin-bottom: 10px;
}

.imageContentWithLink {
    position: relative;

    > a:before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }
}

.imageContentImage {
    width: 100%;
    border: 1px solid $pewter1;
    margin-bottom: 15px;
    aspect-ratio: 1;
}

.threeToFour {
    aspect-ratio: 0.75;
}

.grid {
    composes: grid from "../../styles/layout.module.scss";
}

.twoColumn {
    > * {
        grid-column: span 6;

        @media ($below-mobile) {
            grid-column: span 4;
        }
    }
}

.threeColumn {
    > * {
        grid-column: span 4;

        @media ($below-tablet) and ($above-mobile) {
            grid-column: span 6;
        }
    }
}

.fourColumn {
    > * {
        grid-column: span 3;

        @media ($below-tablet) {
            grid-column: span 4;
        }

        @media ($below-mobile) {
            grid-column: span 2;
        }
    }
}
